import store from '@/store';

export default {
  trackEventApi(name, properties) {
    const defaultProperties = {
      locale: store.state.context.locale,
      host: store.state.hostname,
      app: 'online signup'
    };
    const allProperties = { ...defaultProperties, ...properties };
    //adding console as backend is not yet done, once we have backend we can send this data to backend
    /* eslint-disable-next-line no-console */
    console.log(name, allProperties);
  }
};
